import API from "@utils/request";
import { PAGE_SIZE } from "@config";

const HOME = "/education/schschool/";

/**
 * 获取学校列表（分页）
 * @param {object} params
 * @param {object} pages
 */
export async function schoolList(params) {
  try {
    return await API.get(HOME + "list", {
      params: {
        ...params,
        headers: { isLoading: true },
      },
    });
  } catch (error) {
    return error;
  }
}

/**
 * 导出学校数据
 * @param {object} params
 * @param {object} pages
 */
export async function exportData(params) {
  try {
    return await API.post(HOME + "export", params);
  } catch (error) {
    return error;
  }
}

