<style scoped lang="scss">
.curriculum {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.look-btn {
  width: 60px;
  padding: 0;
  height: 30px;
  line-height: 30px;
  color: #6340c8;
  background-color: #f2edff;
  border-radius: 6px;
  border: solid 1px #c0adf9;

  &:hover {
    color: #ffffff;
    background-color: #6340c8;
    border: 1px solid #6340c8;

    &:before,
    &:after {
      background: #6340c8;
    }
  }
}

.curriculum-search {
  flex: none;
}

.curriculum-table {
  flex: 1;
  overflow: hidden;

  .table_center {
    height: calc(100% - 60px);
    overflow-y: scroll;
  }
}



// 分页
.paging {
  padding: 10px 0;
  text-align: right;
}

// 新增课程
.new-courses {
  width: 100%;
  height: 68px;
  line-height: 68px;
  background-color: #f6f7fc;
  font-size: 16px;
  font-weight: 600;
  color: #1b1b1b;
  position: relative;
  text-indent: 36px;
}

.newcourses {
  .new-courses-content {
    background: #ffffff;
    padding: 50px 90px 43px;
    box-sizing: border-box;
  }
}

.new-courses::after {
  content: "";
  width: 5px;
  height: 18px;
  background-color: #6340c8;
  border-radius: 2.5px;
  position: absolute;
  top: 26px;
  left: 25px;
}

::v-deep {
  .newcourses .el-dialog {
    border-radius: 10px;
    overflow: hidden;
  }

  .el-dialog__header {
    padding: 0;
  }

  .el-dialog__body {
    padding: 0;
  }

  .curriculum-search .el-input--medium .el-input__inner {
    min-width: 250px;

    background: #f1f2f8;
    border-radius: 10px;
    border: none;
  }

  // .curriculum-search .el-form-item--medium .el-form-item__label {
  //   line-height: 50px;
  // }
  .curriculum-search .el-form--inline .el-form-item {
    margin-right: 19px;
  }


}

// 状态
.normal {
  font-size: 14px;
  color: #40c84a;
}

.unusual {
  font-size: 14px;
  color: #e53f32;
}

// 新增课程
::v-deep {
  .new-courses-content .el-input--medium .el-input__inner {
    height: 46px;
    line-height: 46px;
    width: 393px;
    background: #f6f7fa;
    border: none;
    border-radius: 10px;
  }

  .new-courses-content .el-form-item--medium .el-form-item__label {
    line-height: 46px;
  }

  .el-dialog .el-dialog__body {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}
</style>
<template>
  <section class="curriculum">
    <div class="curriculum-search">
      <el-form :inline="true" :model="searchform" class="demo-form-inline">
        <!-- <el-form-item label="学校">
          <el-select v-model="searchform.grades" placeholder="请选择学校">
            <el-option label="区域一" value="shanghai"></el-option>
            <el-option label="区域二" value="beijing"></el-option>
          </el-select>
        </el-form-item> -->

        <el-form-item label="">
          <el-input v-model="searchform.query_word" placeholder="请输入关键字"></el-input>
        </el-form-item>

        <el-form-item>
          <el-button type="primary" @click="init('search')" class="lightpurple-btn">搜索</el-button>
          <el-button type="primary" @click="onSubmit" class="lightyellow-btn" v-loading="btnLoad">导出</el-button>
        </el-form-item>
      </el-form>
    </div>

    <div class="curriculum-table">
      <template>
        <div class="table_center">
          <el-table :data="tableData" style="width: 100%" :header-cell-style="{
            background: '#E8EAF3',
            color: '#1a1a1b',
            'text-align': 'center',
            fontWeight: 500,
          }">

            <el-table-column prop="scsch_id" label="ID"> </el-table-column>
            <el-table-column prop="scsch_name" label="学校名称" min-width="140"> </el-table-column>
            <el-table-column prop="scsch_province_city_area" label="省/市/区" min-width="140"> </el-table-column>
            <!-- <el-table-column prop="market" label="市"> </el-table-column> -->
            <el-table-column prop="type" label="学校类型">
              <template slot-scope="scope">
                <p>{{ scope.row.scsch_type == 10 ? "小学" : scope.row.scsch_type == 20 ? "初中" : scope.row.scsch_type == 30 ?
                  "高中": scope.row.scsch_type == 40? "职高":'未知' }}
                </p>
              </template>
            </el-table-column>
            <el-table-column prop="scuse_name" label="学校管理员"> </el-table-column>
            <el-table-column prop="create_time" label="添加时间"> </el-table-column>
            <el-table-column label="状态" width="100">
              <template slot-scope="scope">
                <span :class="{ normal: scope.row.scsch_status == 10, unusual: scope.row.scsch_status == 20 }">{{
                  scope.row.scsch_status == 10 ? "启用" : "禁用" }}</span>
              </template>
            </el-table-column>
          </el-table>
        </div>


        <div class="paging">
          <el-pagination @current-change="handleCurrentChange" :current-page.sync="currentPage" :page-size="10"
            next-text="下一页" layout=" pager, next, jumper" :total="total">
          </el-pagination>
        </div>
      </template>
    </div>

    <!-- 新增课程 -->
    <el-dialog :show-close="false" width="659px" class="newcourses" :visible.sync="dialogTableVisible">
      <div class="new-courses">新增课程</div>

      <div class="new-courses-content">
        <el-form label-width="80px" :model="newcoursesData">
          <el-form-item label="科目" prop="region">
            <el-select v-model="newcoursesData.name" placeholder="请选择科目">
              <el-option label="区域一" value="shanghai"></el-option>
              <el-option label="区域二" value="beijing"></el-option>
            </el-select>
          </el-form-item>

          <el-form-item label="课时">
            <el-input v-model="newcoursesData.name"></el-input>
          </el-form-item>

          <el-form-item label="上课时间" prop="region">
            <el-select v-model="newcoursesData.name" placeholder="请选择上课时间">
              <el-option label="区域一" value="shanghai"></el-option>
              <el-option label="区域二" value="beijing"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="上课班级" prop="region">
            <el-select v-model="newcoursesData.name" placeholder="请选择上课班级">
              <el-option label="区域一" value="shanghai"></el-option>
              <el-option label="区域二" value="beijing"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="上课方式" prop="region">
            <el-select v-model="newcoursesData.name" placeholder="请选择上课方式">
              <el-option label="区域一" value="shanghai"></el-option>
              <el-option label="区域二" value="beijing"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="课程内容">
            <el-input v-model="newcoursesData.name"></el-input>
          </el-form-item>
          <el-form-item label="授课教师" prop="region">
            <el-select v-model="newcoursesData.name" placeholder="请选择授课教师">
              <el-option label="区域一" value="shanghai"></el-option>
              <el-option label="区域二" value="beijing"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="适合年级" prop="region">
            <el-select v-model="newcoursesData.name" placeholder="请选择适合年级">
              <el-option label="区域一" value="shanghai"></el-option>
              <el-option label="区域二" value="beijing"></el-option>
            </el-select>
          </el-form-item>

          <el-form-item style="
              margin-top: 49px;
              padding-top: 26px;
              text-align: right;
              border-top: 1px solid #f8f8f8;
            ">
            <el-button type="primary" class="lightgrey-btn" @click="dialogTableVisible = false">取消</el-button>
            <el-button class="lightpurple-btn" type="primary">确定</el-button>
          </el-form-item>
        </el-form>
      </div>
    </el-dialog>
  </section>
</template>

<script>
import { schoolList ,exportData} from "@api/school";
export default {
  name: "",
  data() {
    return {
      currentPage: 1,
      total: 0,
      searchform: {
        pageindex: 1,
        pagesize: 10,
      },
      btnLoad:false,

      // 表格数据
      tableData: [],

      // 新增课程
      dialogTableVisible: false,

      newcoursesData: {
        name: "课程",
      },
    };
  },

  mounted() {
    // let doc = document.querySelector(".el-pagination");
    // let liElement = document.createElement("button");
    // liElement.setAttribute("class", "surebtn");
    // var liText = document.createTextNode("确定");
    // liElement.appendChild(liText);
    // doc.appendChild(liElement);
  },
  created() {
    this.init();
  },
  methods: {
    /**初始化 */
    async init(val) {
      if (val == "search") {
        this.searchform.pageindex = 1;
      }
      const res = await schoolList(this.searchform);
      if (res.status == 200) {
        this.tableData = res.data.data;
        this.total = res.data.allcount;
      }
    },
    async onSubmit() { 
      this.btnLoad = true;
            let { data } = await exportData(this.searchform);
            
            this.btnLoad = false;
            if (data.full_file_url) {
                this.$fileDownload(data.full_file_url)
            };

    },
    handleClick() {
      this.$router.push({ path: "/course/info" });
      // this.$router.push({path: '/login?url=' + this.$route.path});
    },

    // 修改当前
    handleCurrentChange() {
      this.searchform.pageindex = this.currentPage;
      this.init()
    },
  },
};
</script>
